import * as React from "react"
import { graphql } from "gatsby"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Card from "../components/card"
import TagFilter from "../components/tagFilter"


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  let current = [];
  let past = [];
  const nodes = data.allMarkdownRemark.nodes;
  nodes.forEach(node => (
    node.frontmatter.tags.includes('current') ? current.push(node) : past.push(node)
  ));

  //const posts = past.map(value => ({ value, sort: Math.random() }))
  // .sort((a, b) => a.sort - b.sort)
  // .map(({ value }) => value);
  const posts = past;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <Seo title="Nelson Wittwer" />
      <h3>The Current Binge</h3>
      <ResponsiveMasonry
        columnsCountBreakPoints={{350: 2, 900: 3, 1200: 4}}
      >
        <Masonry gutter='10px'>
          {current.map(post => {
            return (
              <Card key={post.fields.slug} post={post}/>
            )
          })}
        </Masonry>
      </ResponsiveMasonry>

      <h4>The my space for Nelson.</h4>
      <p>Stuff I've enjoyed in the past.</p>
      <TagFilter/>
      <ResponsiveMasonry
        columnsCountBreakPoints={{350: 2, 900: 3, 1200: 4}}
      >
        <Masonry gutter='10px'>
          {posts.map(post => {
            return (
              <Card key={post.fields.slug} post={post}/>
            )
          })}
        </Masonry>
      </ResponsiveMasonry>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          img
          tags
        }
      }
    }
  }
`
