import * as React from "react"
import Tag from './tag'

const Card = (props) => {
  const tags = props.post.frontmatter.tags.filter(tag => tag !== 'current') || [];

  return (
    <div className="grid-item">
      <article
        className="card"
        itemScope
        itemType="http://schema.org/Article"
      >
        <img className='card-img' src={props.post.frontmatter.img} alt={props.post.frontmatter.title}></img>
        <div className="card-title">
          <ul className='content-tags'>
            {tags.map(function (tagName, idx) {
              return (
                <li key={idx}>
                  <Tag key={idx} name={tagName} />
                </li>
              )
            })}
          </ul>
          <span>{props.post.frontmatter.title}</span>
        </div>
      </article>
    </div>
  )
}

export default Card
