import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Tag from './tag'

const TagFilter = (props) => {
  const tagsQuery = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          frontmatter {
            tags
          }
        }
      }
    }
  `)
  const tagsUsed = tagsQuery.allMarkdownRemark.nodes.map(node => {
    return node.frontmatter.tags
  }).flat();
  const unique = (value, index, self) => {
    return self.indexOf(value) === index
  }
  const tagNames = tagsUsed.filter(unique);

  return (
    <div className="tag-filter">
      <ul className='content-tags'>
        {tagNames.map(function(tagName, idx){
          return (
            <li key={idx}>
              <Tag key={idx} name={tagName}/>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default TagFilter
